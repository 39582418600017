
import axios from 'axios';
import { getFromStorage } from '../lib/utils';

const baseUrl = process.env.REACT_APP_BASE_URL_NEW
const BaseUrl = process.env.REACT_APP_BASE_URL_API
const newBaseUrl = process.env.REACT_APP_BASE_URL_NEW
export const API = {
    baseUrl:baseUrl,
    BaseUrl:BaseUrl,
    newBaseUrl:newBaseUrl
}

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${getFromStorage("accessToken")}`,
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_Ocp_Apim_Subscription_Key,
  }
});

export default axiosInstance;
