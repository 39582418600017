import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";
import { AnimatePresence } from "framer-motion";
import SuspenseLoader from "./SuspenseLoader";
import Orders from "../pages/Investigation/Orders";
import PharmOrders from "../pages/Pharmacy/Orders";
import PriceList from "../pages/Investigation/PriceList";
import PharmPriceList from "../pages/Pharmacy/PriceList";
import OrdersProvider from "../pages/Investigation/Orders/context";
import PharmOrdersProvider from "../pages/Pharmacy/Orders/context";
import UploadPricelist from "../pages/Investigation/PriceList/UploadPricelist";
import PharmUploadPricelist from "../pages/Pharmacy/PriceList/UploadPricelist";

import Wallet from "../pages/Investigation/Wallet";
import TariffEmail from "../pages/TariffEmail";
import { ToastContainer } from "react-toastify";
import HospitalFlowLayout from "../layouts/HospitalFlow";
import MainLayout from "../layouts/MainLayout.jsx";
import Billsdetails from "../pages/Hospital/Bills/Billsdetails.jsx";
import OnlineConsultationHistory from "../pages/consultation/OnlineConsultationHistory";
import ConsultationHistoryDetails from "../pages/consultation/ConsultationHistoryDetails";
import CreateConsultation from "../pages/consultation/CreateConsultation";
import Tariff from "../pages/Pharmacy/pharmacyTariffs/PharmacyTariff";
import HospitalTariffs from "../pages/Hospital/hospitalTariffs/HospitalTariffs";
import PharmacyTariff from "../pages/Pharmacy/pharmacyTariffs/PharmacyTariff";
import PharmacyTariffBreakDown from "../pages/Pharmacy/pharmacyTariffs/PharmacyTariffBreakDown";
import HospitalTariffBreakDown from "../pages/Hospital/hospitalTariffs/HospitalTariffBreakDown";
import InvestigationTariff from "../pages/Investigation/investigationTariff/InvestigationTariff";
import InvestigationTariffBreakDown from "../pages/Investigation/investigationTariff/InvestigationTariffBreakDown";

const EmailVerificationPage = React.lazy(() =>
  import("../pages/EmailVerificationPage")
);
const FacilityDetails = React.lazy(() => import("../pages/FacilityDetails"));
const LoginPage = React.lazy(() => import("../pages/LoginPage"));
const Facility = React.lazy(() => import("../layouts/FacilitydetailsLayout"));
const TariffFacilityDetails = React.lazy(() =>
  import("../pages/user/TariffFacilityDetails")
);
const ChooseOwnershipStatus = React.lazy(() =>
  import("../components/ChooseAccountOwnershipStatus")
);
// const Tariff = React.lazy(() => import("../pages/tariffs/Tariff"));
const TariffBreakDown = React.lazy(() =>
  import("../pages/tariffs/TariffBreakDown")
);
const TarrifBreakdown = React.lazy(() =>
  import("../components/TarrifBreakdown")
);
const AddFacility = React.lazy(() => import("../components/AddFacility"));
const InvestigationFlowLayout = React.lazy(() =>
  import("../layouts/InvestigationFlow")
);
const Home = React.lazy(() => import("../pages/Investigation/Home"));
const PharmHome = React.lazy(() => import("../pages/Pharmacy/Home"));
const HospHome = React.lazy(() => import("../pages/Hospital/Home"));
const Details = React.lazy(() => import("../pages/Hospital/Home/Details"));
const Patients = React.lazy(() => import("../pages/Hospital/Patients"));
const Bills = React.lazy(() => import("../pages/Hospital/Bills"));
const BillsDetails = React.lazy(() => import("../pages/Hospital/Bills/Billsdetails.jsx"))
const Notifications = React.lazy(() => import("../pages/Notifications.jsx"));
const Router = () => {
  return (
    <AnimatePresence mode="sync">
      <Suspense 
        fallback={<Loader />}
        className="bg-[#EFEFF3] font-helvetica "
        style={{ position: "relative" }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <SuspenseLoader>
                <LoginPage />
              </SuspenseLoader>
            }
          />
          <Route
            path="/emailverificationpage"
            element={
              <SuspenseLoader>
                <EmailVerificationPage />
              </SuspenseLoader>
            }
          />
          <Route
            path="/tariffemail"
            element={
              <SuspenseLoader>
                <TariffEmail />
              </SuspenseLoader>
            }
          />
          {/* <Route
          path="/notifications"
          element={
            <SuspenseLoader>
              <Notifications />
            </SuspenseLoader>
  
          }
           /> */}

          <Route
            path="/facility/*"
            element={
              <SuspenseLoader>
                <Facility />
              </SuspenseLoader>
            }
          >
            <Route
              path="choose-account"
              element={
                <SuspenseLoader>
                  <ChooseOwnershipStatus />
                </SuspenseLoader>
              }
            />
            <Route
              path="add-facility"
              element={
                <SuspenseLoader>
                  <AddFacility />
                </SuspenseLoader>
              }
            />
              <Route
                path="tariff-facility"
                element={
                  <SuspenseLoader>
                    <TariffFacilityDetails />
                  </SuspenseLoader>
                }
              />
            <Route
              path="tarrif-breakdown"
              element={
                <SuspenseLoader>
                  <TarrifBreakdown />
                </SuspenseLoader>
              }
            />
            <Route
              path="tarrifs"
              element={
                <SuspenseLoader>
                  <Tariff />
                </SuspenseLoader>
              }
            />
            <Route
              path="tarrif-breakdown"
              element={
                <SuspenseLoader>
                  <TarrifBreakdown />
                </SuspenseLoader>
              }
            />
            <Route
              path="activation/:email/:type"
              element={
                <SuspenseLoader>
                  <FacilityDetails />
                </SuspenseLoader>
              }
            />
            <Route path="*" element={<p>This should be the 404 page</p>} />
          </Route>
          <Route
            path="/investigation/*"
            element={
              <SuspenseLoader>
                <OrdersProvider>
                  <MainLayout />
                </OrdersProvider>
              </SuspenseLoader>
            }
          >
            <Route
              path="dashboard"
              element={
                <SuspenseLoader>
                  <Home />
                </SuspenseLoader>
              }
            />
              <Route
                path="tariff-facility"
                element={
                  <SuspenseLoader>
                    <TariffFacilityDetails />
                  </SuspenseLoader>
                }
              />
              <Route
              path="tariffs"
              element={
                <SuspenseLoader>
                  <InvestigationTariff />
                </SuspenseLoader>
              }
            />
              <Route
              path="investigation-tariffs-details/:id"
              element={
                <SuspenseLoader>
                  <InvestigationTariffBreakDown />
                </SuspenseLoader>
              }
            />
            <Route
              path="orders"
              element={
                <SuspenseLoader>
                  <Orders />
                </SuspenseLoader>
              }
            />
            <Route
              path="upload-pricelist"
              element={
                <SuspenseLoader>
                  <UploadPricelist />
                </SuspenseLoader>
              }
            />
            <Route
              path="pricelist"
              element={
                <SuspenseLoader>
                  <PriceList />
                </SuspenseLoader>
              }
            />
            <Route
              path="wallet"
              element={
                <SuspenseLoader>
                  <Wallet />
                </SuspenseLoader>
              }
            />
          </Route>
          <Route
            path="/pharmacy/*"
            element={
              <SuspenseLoader>
                <PharmOrdersProvider>
                  <MainLayout />
                </PharmOrdersProvider>
              </SuspenseLoader>
            }
          >
            <Route
              path="dashboard"
              element={
                <SuspenseLoader>
                  <PharmHome />
                </SuspenseLoader>
              }
            />
            <Route
              path="orders"
              element={
                <SuspenseLoader>
                  <PharmOrders />
                </SuspenseLoader>
              }
            />
            <Route
              path="pharm-upload-pricelist"
              element={
                <SuspenseLoader>
                  <PharmUploadPricelist />
                </SuspenseLoader>
              }
            />
            <Route
              path="pricelist"
              element={
                <SuspenseLoader>
                  <PharmPriceList />
                </SuspenseLoader>
              }
            />
            <Route
              path="tariffs"
              element={
                <SuspenseLoader>
                  <PharmacyTariff />
                </SuspenseLoader>
              }
            />
            <Route
              path="pharmacy-tariffs-details/:id"
              element={
                <SuspenseLoader>
                  <PharmacyTariffBreakDown />
                </SuspenseLoader>
              }
            />
            <Route
              path="tariff-breakdown/:id"
              element={
                <SuspenseLoader>
                  <TariffBreakDown />
                </SuspenseLoader>
              }
            />
            <Route
              path="tariff-facility"
              element={
                <SuspenseLoader>
                  <TariffFacilityDetails />
                </SuspenseLoader>
              }
            />
            <Route
              path="wallet"
              element={
                <SuspenseLoader>
                  <Wallet />
                </SuspenseLoader>
              }
            />
            <Route
              path="notifications"
              element={
                <SuspenseLoader>
                  <Notifications />
                </SuspenseLoader>
              }
            />
          </Route>
          <Route
            path="/hospital/*"
            element={
              <SuspenseLoader>
                <PharmOrdersProvider>
                  <MainLayout specialLinks={true} />
                </PharmOrdersProvider>
              </SuspenseLoader>
            }
          >
            <Route
              path="tariff-facility"
              element={
                <SuspenseLoader>
                  <TariffFacilityDetails />
                </SuspenseLoader>
              }
            />
            <Route
              path="dashboard"
              element={
                <SuspenseLoader>
                  <HospHome />
                </SuspenseLoader>
              }
            />
            <Route
              path=":id"
              element={
                <SuspenseLoader>
                  <Details />
                </SuspenseLoader>
              }
            />
            <Route
              path="orders"
              element={
                <SuspenseLoader>
                  <PharmOrders />
                </SuspenseLoader>
              }
            />
            <Route
              path="tariffs"
              element={
                <SuspenseLoader>
                  <HospitalTariffs />
                </SuspenseLoader>
              }
            />
            <Route
              path="hospital-tariffs-details/:id"
              element={
                <SuspenseLoader>
                  <HospitalTariffBreakDown />
                </SuspenseLoader>
              }
            />
            <Route
              path="consultation-history"
              element={
                <SuspenseLoader>
                  <OnlineConsultationHistory />
                </SuspenseLoader>
              }
            />
            <Route
              path="consultation-history-details/:id"
              element={
                <SuspenseLoader>
                  <ConsultationHistoryDetails />
                </SuspenseLoader>
              }
            />
            <Route
              path="create-consultation"
              element={
                <SuspenseLoader>
                  <CreateConsultation />
                </SuspenseLoader>
              }
            />
            <Route
              path="pharm-upload-pricelist"
              element={
                <SuspenseLoader>
                  <PharmUploadPricelist />
                </SuspenseLoader>
              }
            />
            <Route
              path="pricelist"
              element={
                <SuspenseLoader>
                  <PharmPriceList />
                </SuspenseLoader>
              }
            />
            <Route
              path="patient"
              element={
                <SuspenseLoader>
                  <Patients />
                </SuspenseLoader>
              }
            />
            <Route
              path="bills"
              element={
                <SuspenseLoader>
                  <Bills />
                </SuspenseLoader>
              }
            />
            <Route
              path="billsdetails/:id"
              element={
                <SuspenseLoader>
                  <BillsDetails />
                </SuspenseLoader>
              }
            />

            <Route
              path="wallet"
              element={
                <SuspenseLoader>
                  <Wallet />
                </SuspenseLoader>
              }
            />
            <Route
              path="notifications"
              element={
                <SuspenseLoader>
                  <Notifications />
                </SuspenseLoader>
              }
            />
          </Route>

          <Route path="*" element={<p>This should be the 404 page</p>} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </AnimatePresence>
  );
};

export default Router;
