import EmptyComponent from "../../components/EmptyComponent";
import React, { useState } from "react";
import { BiSolidChevronRight } from "react-icons/bi";
import { MdSearch } from "react-icons/md";
import { TfiMenuAlt } from "react-icons/tfi";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyWallet from "../../assets/empty-search.png";
import { PiArrowLeftFill } from "react-icons/pi";
import { FaPlus } from "react-icons/fa6";

const dummyData = [
  {
    id: 1,
    date: "15th Jan 2023, 2:00 PM",
    name: "Dr. Martins Anderson",
  },
  {
    id: 2,
    date: "15th Jan 2023, 2:00 PM",
    name: "Dr. Martins Anderson",
  },
  {
    id: 3,
    date: "15th Jan 2023, 2:00 PM",
    name: "Dr. Martins Anderson",
  },
  {
    id: 4,
    date: "15th Jan 2023, 2:00 PM",
    name: "Dr. Martins Anderson",
  },
  {
    id: 5,
    date: "15th Jan 2023, 2:00 PM",
    name: "Dr. Martins Anderson",
  },
];

const OnlineConsultationHistory = () => {
    const {state} = useLocation();
  const navigate = useNavigate();
  
  return (
    <div className="text w-full">
      <div className="text flex items-center gap-5 mb-5">
        <button
          onClick={() => navigate(-1)}
          className="text bg-sky-500 w-5 h-5 flex text-white items-center justify-center rounded-full"
        >
          <PiArrowLeftFill />
        </button>
        <h1 className="text-sm font-bold text-[#090F47] ">
            {state === "offline" ?
            "Offline Consultation History" :
            "Online Consultation History"
            }
        
        </h1>
      </div>

      <div className="text w-full bg-white p-6">
        {state === "offline" && (
          <div className="text-[10px] flex justify-end">
            <button onClick={() => navigate("/hospital/create-consultation")} className="text-white gap-2 flex items-center py-2 bg-[#2CBCEF] px-5 rounded-md">
              <FaPlus />
              Add New
            </button>
          </div>
        )}
        <div className="text">
          {dummyData.length > 0 ? (
            dummyData.map((item) => (
              <>
                <div
                  key={item.id}
                  className="text-sm flex gap-3  items-center justify-between py-3 px-5 my-5 rounded-lg bg-[#f9f9f9]"
                >
                  <div className="text gap-5">
                    <div className="text-[#090F47] font-bold">{item.name}</div>
                    <div className="text-[10px]">{item.date}</div>
                  </div>
                  <div className="text">
                    <button
                      onClick={() =>
                        navigate(
                          `/hospital/consultation-history-details/${item.id}`,
                          {
                            state: item,
                          }
                        )
                      }
                      className="text"
                    >
                      <BiSolidChevronRight className="text" />
                    </button>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div className=" h-[50%] my-5 mt-32 flex justify-center items-center font-[700] text-[24px] text-[#808080] ">
              <EmptyComponent
                svgPicture={EmptyWallet}
                title={`${"You are not under any tariff plan"}`}
                caption={"When you have tariff(s), it will be shown here. "}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnlineConsultationHistory;
