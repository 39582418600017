import EmptyWallet from "../../../assets/empty-search.png";
import axiosInstance from "../../../api/constants";
import React, { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { PiArrowLeftFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BiLoaderAlt } from "react-icons/bi";
import EmptyComponent from "../../../components/EmptyComponent";

const InvestigationTariffBreakDown = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [investigationTariff, setInvestigationTariff] = useState([]);
  const { id } = useParams();
  const data = useSelector((state) => state?.user?.user);

  useEffect(() => {
    setIsLoading(true);
    const body = {
      facilityId: data.facilityId,
      tariffId: id,
    };
    axiosInstance
      .post(`/lab/api/Lab/GetTariffPriceList`, body)
      .then((response) => {
        setInvestigationTariff(response?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [data, id]);

  return (
    <div className="w-full">
      <div className="text fixed w-full bg-white p-2 flex items-center gap-5 mb-5">
        <button
          onClick={() => navigate(-1)}
          className="text bg-sky-500 w-5 h-5 flex text-white items-center justify-center rounded-full"
        >
          <PiArrowLeftFill />
        </button>
        <h1 className="text-sm font-bold">Accepted Tariff Breakdown</h1>
      </div>
      {isLoading ? (
        <div className="text w-full fixed h-[70vh] flex justify-center items-center">
          <BiLoaderAlt className=" w-full h-32 text-[#2CBCEF] animate-spin" />
        </div>
      ) : (
        <>
          <div className="text w-full bg-white px-3 py-6">
            <div className="text flex relative gap-5 w-full">
              <div className="text border-b absolute right-0 w-2/5 flex items-center">
                <input
                  type="search"
                  className="text-xs w-full h-full pl-11 outline-none focus:outline-none py-3"
                  placeholder="Search..."
                />
                <MdSearch className="absolute left-0  bg-white w-10 p-3 h-full" />
              </div>
            </div>
            {investigationTariff.length > 0 ? (
              <div className="text mt-32">
                <div className="text-sm grid grid-cols-4 pl-5 font-bold">
                  <div className="text">S/N</div>
                  <div className="text -ml-10">Medication Name</div>
                  <div className="text ml-14">Service</div>
                  <div className="text text-center">Price</div>
                </div>
                {investigationTariff.map((item, index) => (
                  <>
                    <div
                      key={item.name}
                      className="text-sm gap-3 grid grid-cols-4 py-4 pl-5 my-4 rounded bg-gray-50"
                    >
                      <div className="text">{index + 1}</div>
                      <div className="text -ml-10">{item.serviceName}</div>
                      <div className="text ml-14">{item.category}</div>
                      <div className="text text-center">N {item.price.toLocaleString()}</div>
                    </div>
                  </>
                ))}
              </div>
            ) : (
              <div className=" h-[50%] my-5 mt-32 flex justify-center items-center font-[700] text-[24px] text-[#808080] ">
                <EmptyComponent
                  svgPicture={EmptyWallet}
                  title={`${"There's no accepted tariff breakdown yet"}`}
                  caption={"When you have tariff(s), it will be shown here. "}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default InvestigationTariffBreakDown;
