import {
  BarSpan,
  BarWrapper,
  IndicatorSpan,
  IndicatorWrapper,
} from "./Home.styled";

const RatingBar = ({ stars, count, width }) => {
  return (
    <BarWrapper>
      <BarSpan $left>{stars}</BarSpan>
      <IndicatorWrapper>
        <IndicatorSpan $width={width}></IndicatorSpan>
      </IndicatorWrapper>
      <BarSpan>{count}</BarSpan>
    </BarWrapper>
  );
};

export default RatingBar;
