import * as React from "react";
import { cn } from "../../../lib/utils";
// import CurrencyInput from "react-currency-input-field";

const Input = React.forwardRef(
   ({ className, type, isNumeric, ...props }, ref) => {
      const handleKeyPress = (event) => {
         // Prevent non-numeric input
         if (isNumeric && !/[0-9.,]/.test(event.key)) {
            event.preventDefault();
         }
      };

      return (
         <input
            type={type}
            className={cn(
               "flex h-10 w-full rounded-md border border-shadcn-input- bg-shadcn-background py-2 text-sm ring-offset-shadcn-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-shadcn-muted-foreground focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-shadcn-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50",
               className
            )}
            ref={ref}
            onKeyPress={handleKeyPress}
            {...props}
         />
      );
   }
);
Input.displayName = "Input";

// const CurrencyInputField = React.forwardRef(
//    ({ className, decimalsLimit = 2, defaultValue = "0.00", ...props }, ref) => {
//       return (
//          <CurrencyInput
//             className={cn(
//                "flex h-10 w-full rounded-md border border-shadcn-input bg-shadcn-background px-3 py-2 text-sm ring-offset-shadcn-background placeholder:text-shadcn-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-shadcn-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
//                className
//             )}
//             ref={ref}
//             decimalsLimit={decimalsLimit}
//             defaultValue={Number(defaultValue)}
//             allowDecimals
//             {...props}
//          />
//       );
//    }
// );

// CurrencyInputField.name = "CurrencyInputField";

export { Input };
