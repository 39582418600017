import React from "react";

const EmptyComponent = ({ className, svgPicture,title,caption }) => {
    return (
        <div>
            <img
                src={svgPicture}
                alt=""
                className={` ${className ? className : ""} mx-auto`}
            />
            <p className="text font-semibold text-black text-center">{title}</p>
            <p className="text-center text-sm mt-2">
                {caption}
            </p>
        </div>
    );
};

export default EmptyComponent;
