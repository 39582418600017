import EmptyComponent from "../../../components/EmptyComponent";
import EmptyWallet from "../../../assets/empty-search.png";
import axiosInstance from "../../../api/constants";
import React, { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { PiArrowLeftFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BiLoaderAlt } from "react-icons/bi";

const HospitalTariffBreakDown = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hospitalTariff, setHospitalTariff] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  // const [tabs, setTabs] = useState(1);
  const data = useSelector((state) => state?.user?.user);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
    .post(
      `/hospital/GetPriceListForHospital?facilityId=${data.facilityId}&tariffId=${id}`
    )
    .then((response) => {
      setHospitalTariff(response?.data?.data);
      setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [data, id]);

  return (
    <div className="w-full">
      <div className="text fixed w-full p-2 bg-white flex items-center gap-5 mb-5">
        <button
          onClick={() => navigate(-1)}
          className="text bg-sky-500 w-5 h-5 flex text-white items-center justify-center rounded-full"
        >
          <PiArrowLeftFill />
        </button>
        <h1 className="text-sm font-bold">Accepted Tariff Breakdown</h1>
      </div>
      <div className="text w-full bg-white px-3 py-6">
        {/* <div className="text-center w-1/2 flex mx-auto my-5">
          <ul className="text-[10px] flex rounded justify-around w-full py-2 bg-white shadow">
            <li
              onClick={() => setTabs(1)}
              className={`rounded cursor-pointer hover:underline px-1 py-1 ${
                tabs === 1 ? "bg-[#2CBCEF] text-white" : ""
              }`}
            >
              Consultation
            </li>
            <li
              onClick={() => setTabs(2)}
              className={`rounded cursor-pointer hover:underline px-1 py-1 ${
                tabs === 2 ? "bg-[#2CBCEF] text-white" : ""
              }`}
            >
              Medication
            </li>
            <li
              onClick={() => setTabs(3)}
              className={`rounded cursor-pointer hover:underline px-1 py-1 ${
                tabs === 3 ? "bg-[#2CBCEF] text-white" : ""
              }`}
            >
              Investigation
            </li>
            <li
              onClick={() => setTabs(4)}
              className={`rounded cursor-pointer hover:underline px-1 py-1 ${
                tabs === 4 ? "bg-[#2CBCEF] text-white" : ""
              }`}
            >
              Treatment Service
            </li>
          </ul>
        </div>{" "} */}
        {isLoading ? (
          <div className="text w-full fixed h-[70vh] flex justify-center items-center">
            <BiLoaderAlt className=" w-full h-32 text-[#2CBCEF] animate-spin" />
          </div>
        ) : (
          <>
            <div className="text flex relative gap-5 w-full">
              <div className="text border-b absolute right-0 w-2/5 flex items-center">
                <input
                  type="search"
                  className="text-xs w-full h-full pl-11 outline-none focus:outline-none py-3"
                  placeholder="Search..."
                />
                <MdSearch className="absolute left-0  bg-white w-10 p-3 h-full" />
              </div>
            </div>
            <div className="text mt-32">
              <div className="text-sm grid grid-cols-4 pl-5 font-bold">
                <div className="text">S/N</div>
                <div className="text -ml-10">Medication Name</div>
                <div className="text ml-14">Service</div>
                <div className="text text-center">Price</div>
              </div>
              {hospitalTariff.length > 0 ? (
                hospitalTariff.map((item, index) => (
                  <>
                    <div
                      key={item.name}
                      className="text-sm gap-3 grid grid-cols-4 py-4 pl-5 my-4 rounded bg-gray-50"
                    >
                      <div className="text">{index + 1}</div>
                      <div className="text -ml-10">{item.category}</div>
                      <div className="text ml-14">{item.serviceName}</div>
                      <div className="text text-center">N {item.price.toLocaleString()}</div>
                    </div>
                  </>
                ))
              ) : (
                <div className=" h-[50%] my-5 mt-32 flex justify-center items-center font-[700] text-[24px] text-[#808080] ">
                  svgPicture={EmptyWallet}
                  <EmptyComponent
                    title={`${"There's no accepted tariff breakdown yet"}`}
                    caption={"When you have tariff(s), it will be shown here. "}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HospitalTariffBreakDown;
