import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
   return twMerge(clsx(inputs));
}
export const getFromStorage = (key) => {
const valueFromStorage = localStorage.getItem(key)
// const parsedValue = JSON.parse(valueFromStorage)
// return parsedValue || null;
return valueFromStorage
}