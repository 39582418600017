import React from "react";
import { BackBtn, PageTitle, RatingsCardsWrapper } from "../Home/Home.styled";
import RatingsCard from "../../Investigation/Home/RatingsCard";
import { ratingsDetails, returnBarDetails } from "../../Investigation/Home/data";

const Billsdetails = () => {
  return (
    <>
      <div className="flex flex-col w-full gap-14">
        <span className="flex items-center gap-4 text-[#090F47] font-bold text-[20px]">
          <BackBtn className="text-primary w-6 h-6 cursor-pointer" />
          Bills Details
        </span>
        <div className="w-full  bg-white p-8 rounded-md">
          <div className="mb-4">
            <div className="flex justify-between mb-4">
              <p className="text-2xl font-semibold text-[#444448]">
                Billing Date:
              </p>
              <span className="text-[#ABABAB] text-base font-medium">
                2024-03-19 12:51 PM
              </span>
            </div>
            <div className="flex justify-between">
              <p className="text-[20px] font-medium text-[#444448]">
                Billing ID:
              </p>
              <span className="text-primary text-base font-medium">
                2345673
              </span>
            </div>
          </div>
          <div className="text-primary font-bold text-lg mb-4">
            Patient Info
          </div>
          <div className="flex justify-between font-[500]">
            <div className="">
              <div className="mb-4">
                <div className="">Name:</div>
                <div className="">Mr. Jide Jones</div>
              </div>

              <div className="mb-4">
                <div className="">CCL ID : </div>
                <div className="">CCL123</div>
              </div>
            </div>
            <div className="">
              <div className="mb-4">
                <div className="">Sex : </div>
                <div className="">Male</div>
              </div>

              <div className="mb-4">
                <div className="">Email: </div>
                <div className="">jideone@gmail.com</div>
              </div>
            </div>
            <div className="">
              <div className="mb-4">
                <div className="">Age : </div>
                <div className="">28</div>
              </div>

              <div className="mb-4">
                <div className="">Phone Number: </div>
                <div className="">07090876543</div>
              </div>
            </div>
          </div>

          <div className=""></div>
        </div>
        <div className="w-full  bg-white p-8 rounded-md">
          <div className="text-primary font-bold text-lg mb-4">
            Medication Details Info
          </div>
          <div className="flex justify-between font-[500]">
            <div className="">
              <div className="mb-4">
                <div className="">Treatment Service:</div>
                <div className="">Caesarean Surgery</div>
              </div>
            </div>
            <div className="">
              <div className="mb-4">
                <div className="">Sub-Treatment :</div>
                <div className="">PET Scan</div>
              </div>
            </div>
            <div className="">
              <div className="mb-4">
                <div className="">Price:</div>
                <div className="">N 9000.00</div>
              </div>
            </div>
          </div>

        </div>
        <div className="p-5 rounded-[20px] bg-secondaryLight">
            <PageTitle className="mb-8">Ratings & Reviews</PageTitle>
            <RatingsCardsWrapper>
              {ratingsDetails.map((detail) => (
                <RatingsCard
                  key={detail.title}
                  cardTitle={detail.title}
                  average={detail.average}
                  barDetails={returnBarDetails(detail)}
                />
              ))}
            </RatingsCardsWrapper>
          </div>

      </div>
    </>
  );
};

export default Billsdetails;
