import {
  Bars,
  CardTitle,
  RatingStar,
  RatingsCardWrapper,
  StarsAndAverage,
  StarsWrapper,
} from "./Home.styled";
import { ratingsArr } from "./data";
import RatingBar from "./RatingBar";

const RatingsCard = ({ cardTitle, average, barDetails }) => {
  return (
    <RatingsCardWrapper>
      <CardTitle>{cardTitle}</CardTitle>
      <StarsAndAverage>
        <StarsWrapper>
          {ratingsArr.map((rating) => (
            <RatingStar key={rating} $filled={rating <= average} />
          ))}
        </StarsWrapper>
        <span>
          {average} out of {ratingsArr.length}
        </span>
      </StarsAndAverage>
      <Bars>
        {barDetails.map((detail) => (
          <RatingBar
            key={detail.stars}
            stars={detail.stars}
            count={detail.count}
            width={(detail.count / 1200) * 100}
          />
        ))}
      </Bars>
    </RatingsCardWrapper>
  );
};

export default RatingsCard;
