import React from "react";
import { PiArrowLeftFill } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import star from "../../assets/consultationstar.svg";
import PostABillModal from "./PostABillModal";

const definative = [
  "Sepsis with focus in the chest and UGS",
  "Acute Appendicitis",
  "Acute Appendicitis",
];

const ConsultationHistoryDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state.date.split(","));

  return (
    <div className="text w-full">
      <div className="text flex items-center gap-5 mb-5">
        <button
          onClick={() => navigate(-1)}
          className="text bg-sky-500 w-5 h-5 flex text-white items-center justify-center rounded-full"
        >
          <PiArrowLeftFill />
        </button>
        <h1 className="text-sm font-bold text-[#090F47]">
          Online Consultation History
        </h1>
      </div>
      <div className="text w-full bg-white p-6">
        <div className="text">
          <div className="text-sm  py-5 px-5 my-5 rounded-lg bg-[#f9f9f9]">
            <div className="text flex items-center justify-between">
              <div className="text-[#090F47] font-bold">
                <span className="text">{state.name}</span>
                <span className="text-gray-600 font-semibold text-xs flex gap-1">
                  <img src={star} alt="" className="text" />
                  4.9 (48 reviews)
                </span>
              </div>
              <div className="text-[10px] grid">
                <span className="text-gray-600 font-semibold text-xs">
                  {state.date.split(",")[0]}
                </span>
                <span className="text-gray-600 font-semibold text-xs text-end">
                  {state.date.split(",")[1]}
                </span>
              </div>
            </div>
          </div>
          <div className="text">
            <h1 className="text-sm">Definitive / Provisional</h1>
            <div className="text-sm border-b py-3">
              {definative?.map((def) => (
                <span className="text border border-[#2CBCEF] mr-3 px-1 py-1 rounded">
                  {def}
                </span>
              ))}
            </div>
          </div>
          <div className="text mt-6">
            <h1 className="text-sm">Differential</h1>
            <div className="text-sm border-b py-3">
              {definative?.map((def) => (
                <span className="text border bg-gray-50 mr-3 px-1 py-1 rounded">
                  {def}
                </span>
              ))}
            </div>
          </div>
          <div className="text mt-6">
            <h1 className="text-sm">Doctor’s notes</h1>
            <div className="text-sm bg-[#F7F7F7] h-60 py-3 px-2 rounded-2xl">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt
            </div>
          </div>
          <div className="text mt-6">
            <h1 className="text-sm">Consultation Summary</h1>
            <div className="text-sm bg-[#F7F7F7] h-60 py-3 px-2 rounded-2xl">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt
            </div>
          </div>
          <div className="text">
            <PostABillModal add={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultationHistoryDetails;
