import { useCreateCloudClinicApiMutation, useDeleteCloudClinicApiMutation, useEditCloudClinicApiMutation } from "./example";
import { toast } from "react-toastify";

export const usePostCreateApiHook = () => {
  const [postApi] = useCreateCloudClinicApiMutation();
  const position = "top-right";

  const post = async (path, datas) => {
    try {
      const response = await postApi({ path, datas });
      if (response?.data?.success) {
        const success = response.data.success;
        const successKey = Object.keys(success)[0];

        console.log(success[successKey]?.description);
      } else {
        const errors = response?.error?.data?.errors;
        if (errors) {
          const errorKey = Object.keys(errors)[0];
          console.log(errors[errorKey]?.description);
        } else {
          console.log(response?.data?.message);
        }
      }
      return response;
    } catch (err) {
      console.log(err);
      toast.error("An error occurred", { position });
    }
  };

  return { post };
};

export const useEditCloudClinicApiHook = () => {
  const [editApi] = useEditCloudClinicApiMutation();
  const position = "top-right";

  const edit = async (path, data) => {
    try {
      const response = await editApi({ path, data });
      if (response?.data?.success) {
        toast.success("Edit successful", { position });
      } else {
        toast.error(response?.data?.message || "Edit failed", { position });
      }
      return response;
    } catch (err) {
      toast.error("An error occurred", { position });
    }
  };

  return { edit };
};

export const useDeleteCloudClinicApiHook = () => {
  const [deleteApi] = useDeleteCloudClinicApiMutation();
  const position = "top-right";

  const deleteItem = async (path) => {
    try {
      const response = await deleteApi({ path });
      if (response?.data?.success) {
        toast.success("Delete successful", { position });
      } else {
        toast.error(response?.data?.message || "Delete failed", { position });
      }
      return response;
    } catch (err) {
      toast.error("An error occurred", { position });
    }
  };

  return { deleteItem };
};
