import React, { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { TfiMenuAlt } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { BiSolidChevronRight } from "react-icons/bi";
import { useSelector } from "react-redux";
import EmptyWallet from "../../../assets/empty-search.png";
import EmptyComponent from "../../../components/EmptyComponent";
import Pagination from "../../../components/Pagination";
import axiosInstance from "../../../api/constants";
import { BiLoaderAlt } from "react-icons/bi";

const HospitalTariffs = () => {
  const [hospitalTariff, setHospitalTariff] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [totalPages, setTotalPages] = useState(7);
  // const [totalItems, setTotalItems] = useState(100);
  // const [loading, setLoading] = useState(true);
  // const [isError, setIsError] = useState(false);
  // const [page, setPage] = useState({ pageNumber: 3, pageSize: 5 });
  const navigate = useNavigate();
  const data = useSelector((state) => state?.user?.user);



  useEffect(() => {
    setIsLoading(true);
    axiosInstance.post(`/hospital/GetPartnerTarrifs?FacilityId=${data.facilityId}`)
    .then((response) => {
      setHospitalTariff(response?.data?.data);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });
  }, [data]);
  

  // const incrementPageNumber = () => {
  //   setPage((prevPage) => ({
  //     ...prevPage,
  //     pageNumber: prevPage.pageNumber + 1,
  //   }));
  // };

  // const decrementPageNumber = () => {
  //   setPage((prevPage) => ({
  //     ...prevPage,
  //     pageNumber: prevPage.pageNumber - 1,
  //   }));
  // };

  // const handlePageNumber = (page) => {
  //   setPage((prevPage) => ({
  //     ...prevPage,
  //     pageNumber: page,
  //   }));
  // };
  return (
    <div className="w-full">
      <div className="text w-full">
        <h1 className="text font-bold ">Tariffs</h1>
          {isLoading? 
          <div className="text w-full fixed h-[70vh] flex justify-center items-center">
            <BiLoaderAlt className=" w-full h-32 text-[#2CBCEF] animate-spin" />
          </div>
          : (
            <>
        <div className="text flex relative gap-5 w-full">
          <div className="text border-b absolute right-0 w-2/5 flex items-center">
            <input
              type="search"
              className="text-xs w-full h-full pl-11 outline-none focus:outline-none py-3"
              placeholder="Search for a tariff"
            />
            <MdSearch className="absolute left-0  bg-white w-10 p-3 h-full" />
          </div>
        </div>
        <div className="text mt-32">
          {hospitalTariff?.length > 0 ? (
            hospitalTariff?.map((item) => (
              <>
              <div
              key={item.id}
              className="text-sm flex gap-3  items-center justify-between py-4 px-5 my-4 rounded bg-white"
              >
                  <div className="text flex items-center gap-5">
                    <div className="text w-7 h-7 flex items-center justify-center bg-sky-50 rounded-full">
                      <TfiMenuAlt className="text-sky-500" />
                    </div>
                    <div className="text">{item.sponsorName}</div>
                    </div>
                  <div className="text">
                  <button onClick={() => navigate(`/hospital/hospital-tariffs-details/${item.tariffId}`, {state:item})} className="text">
                      <BiSolidChevronRight className="text-sky-500" />
                    </button>
                    </div>
                    </div>
                    </>
                  ))
                ) : (
                  <div className=" h-[50%] my-5 mt-32 flex justify-center items-center font-[700] text-[24px] text-[#808080] ">
                  <EmptyComponent
                  svgPicture={EmptyWallet}
                  title={`${"You are not under any tariff plan"}`}
                  caption={"When you have tariff(s), it will be shown here. "}
                  />
                  </div>
                )}
        </div>
        </>
       )}
      </div>
      {/* <Pagination
      incrementPageNumber={incrementPageNumber}
      decrementPageNumber={decrementPageNumber}
      handlePageNumber={handlePageNumber}
      loading={loading}
      isError={isError}
      pageNumber={page.pageNumber}
      totalPages={totalPages}
      total={totalItems}
      /> */}
    </div>
  );
};

export default HospitalTariffs;
