import React, { useCallback, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/shadcn/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../components/shadcn/ui/form";
import editPen from "../../assets/edit-pen.svg";
import { useForm } from "react-hook-form";
import { Label } from "../../components/shadcn/ui/label";
import { Input } from "../../components/shadcn/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/shadcn/ui/select";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import postbillcancel from "../../assets/postbillcancel.svg";
import postbillupload from "../../assets/postbillupload.svg";
import { useDropzone } from "react-dropzone";
import postbillimage from "../../assets/postbillimage.svg";
import postbilltrash from "../../assets/postbilltrash.svg";
import postbillcheckbox from "../../assets/postbillcheckbox.svg";
import postbillplus from "../../assets/postbillplus.svg";
import { ScrollArea } from "../../components/shadcn/ui/scroll-area";
import ConsultationTypeModal from "./ConsultationTypeModal";
import { FaPlus } from "react-icons/fa6";

const nigeriaBanksAndFintech = [
  "Access Bank",
  "First Bank of Nigeria",
  "Zenith Bank",
  "Guaranty Trust Bank (GTBank)",
  "United Bank for Africa (UBA)",
  "Fidelity Bank",
  "Union Bank of Nigeria",
  "Sterling Bank",
  "Ecobank Nigeria",
  "Polaris Bank",
  "Wema Bank",
  "Heritage Bank",
  "Stanbic IBTC Bank",
  "Keystone Bank",
  "Unity Bank",
  "Opay",
  "Paga",
  "Kuda Bank",
  "PalmPay",
  "Flutterwave",
  "Chipper Cash",
  "Moniepoint",
  "Carbon",
  "Paystack",
];

const postbillDummydata = [
  { name: "PET scan", price: "2000" },
  { name: "Admission", price: "5000" },
  { name: "Test", price: "1000" },
];
const postbillDummyfiles = ["image1.jpeg", "image2.jpeg", "image3.jpeg"];

const formSchema = z.object({
  bankName: z.string().min(1, { message: "Bank name is required" }),
  accountNumber: z
    .string()
    .min(11)
    .max(13, { message: "Account number is required" }),
  accountName: z.string().min(1, { message: "Account name is required" }),
});

const PostABillModal = ({ add }) => {
  const [isChecked, setIsChecked] = useState(false);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      bankName: "",
      accountNumber: "",
      accountName: "",
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    const data = new FormData();
    acceptedFiles.forEach((file) => {
      data.append("files", file);
      const reader = new FileReader();

      reader.onload = () => {
        const binaryStr = reader.result;
        console.log(binaryStr);
      };
      reader.readAsArrayBuffer(file);
    });
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // accept: [".xlsx", ".xls"],
  });

  const onSubmit = (e) => {};

  // Function to handle checkbox state change
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <div>
      <Dialog>
        <DialogTrigger asChild>
          {add ? (
            <div className="text-end mt-5">
            <button className="text-white bg-[#2CBCEF] px-10 py-1 rounded-md">Submit</button>
          </div>
          ) : (
            <button className="bg-[#2CBCEF] flex items-center gap-2 px-16 py-2 rounded-lg text-white ">
              Post a Bill
            </button>
          )}
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px] lg:max-w-[550px] bg-white">
          <DialogHeader>
            <DialogTitle className="my-3 text-[#2CBCEF]">
              Post A Bill
            </DialogTitle>
            <DialogDescription>
              <div className="text">
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-5"
                  >
                    <ScrollArea className="w-full h-[78vh]">
                      <FormField
                        control={form.control}
                        name="bankName"
                        render={({ field }) => (
                          <FormItem>
                            <div className="text my-3">
                              <Label className="text-gray-700 text-base">
                                Treatment service
                              </Label>
                              <FormControl>
                                <Select className="w-full">
                                  <SelectTrigger className="w-full border py-6">
                                    <SelectValue placeholder="Select" />
                                  </SelectTrigger>
                                  <SelectContent className="border">
                                    {nigeriaBanksAndFintech?.map((bank) => (
                                      <SelectItem
                                        className="bg-white"
                                        key={bank}
                                        value={bank}
                                      >
                                        {bank}
                                      </SelectItem>
                                    ))}
                                  </SelectContent>
                                </Select>
                              </FormControl>
                            </div>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="accountNumber"
                        render={({ field }) => (
                          <FormItem>
                            <div className="text my-3">
                              <Label className="text-gray-700 text-base">
                                Sub-treatment
                              </Label>
                              <FormControl>
                                <Input
                                  className="border py-6 px-3"
                                  placeholder=""
                                  {...field}
                                  type="number"
                                />
                              </FormControl>
                            </div>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="accountName"
                        render={({ field }) => (
                          <FormItem>
                            <div className="text my-3">
                              <Label className="text-gray-700 text-base">
                                Price
                              </Label>
                              <FormControl>
                                <Input
                                  className="border px-3 py-6"
                                  placeholder=""
                                  {...field}
                                />
                              </FormControl>
                            </div>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <div className="text-end text-sm font-semibold mb-5">
                        <button className="text-[#2CBCEF] border border-[#2CBCEF] px-3 py-0.5 rounded-lg">
                          SAVE
                        </button>
                      </div>
                      <div className="text-sm flex gap-3 my-5">
                        {postbillDummydata?.map((value) => (
                          <div className="text bg-gray-100 flex px-2 py-0.5 rounded-full gap-2">
                            <span className="text-[#1AB044]">
                              {value?.name} - N{value?.price}
                            </span>
                            <button className="tex">
                              <img
                                src={postbillcancel}
                                alt=""
                                className="text"
                              />
                            </button>
                          </div>
                        ))}
                      </div>
                      <div
                        {...getRootProps()}
                        className="text cursor-pointer my-6 bg-[#2CBCEF0D] border-dashed border-2 flex justify-center items-center rounded-lg "
                      >
                        <input {...getInputProps()} />
                        <div className="text-center">
                          <div className="text flex justify-center my-4">
                            <img src={postbillupload} alt="" className="text" />
                          </div>
                          <div className="text-sm text-gray-500 my-4">
                            Upload receipt images
                          </div>
                        </div>
                      </div>
                      <div className="text">
                        <h1 className="text-gray-600 text-sm">
                          Images uploaded to this bill
                        </h1>
                        <div className="text-sm">
                          {postbillDummyfiles?.map((image) => (
                            <div className="text-[#2CBCEF] my-2 flex items-center justify-between">
                              <div className="text flex gap-2">
                                <img
                                  src={postbillimage}
                                  alt=""
                                  className="w-3"
                                />
                                {image}
                              </div>
                              <div className="text flex">
                                <button className="text">
                                  <img
                                    src={postbilltrash}
                                    alt=""
                                    className="w-3"
                                  />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="text py-7">
                        <div className="text flex gap-3 items-center pl-1">
                          {isChecked && (
                            <div
                              className="text"
                              onClick={() => setIsChecked(false)}
                            >
                              <img
                                src={postbillcheckbox}
                                alt=""
                                className="text w-4"
                              />
                            </div>
                          )}
                          {!isChecked && (
                            <input
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                              className=" border border-sky-500"
                            />
                          )}
                          <p className="text">
                            Check this box to mark patient as discharged
                          </p>
                        </div>
                      </div>
                      <div className="text-[#090F47] py-5  text-sm font-bold flex justify-between items-center">
                        <div className="text">Total: </div>
                        <div className="text">NGN 7,000</div>
                      </div>
                      <div className="text-sm py-3 flex justify-between">
                        <div className="text">Add Consultation Information</div>
                        <div className="text">
                          <button className="text cursor-pointer">
                            <img src={postbillplus} alt="" className="text" />
                          </button>
                        </div>
                      </div>
                    </ScrollArea>
                    <div className="text w-full text-center">
                      <ConsultationTypeModal />
                    </div>
                  </form>
                </Form>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PostABillModal;
