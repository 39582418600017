import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/shadcn/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../components/shadcn/ui/form";
import { useForm } from "react-hook-form";
import { Label } from "../../components/shadcn/ui/label";
import { Input } from "../../components/shadcn/ui/input";
import addUser from "../../assets/add user.svg";
import { Switch } from "../../components/shadcn/ui/switch";
import { useNavigate } from "react-router-dom";
import { PiArrowLeftFill } from "react-icons/pi";
import PostABillModal from "./PostABillModal";

const CreateConsultation = () => {
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      managerName: "",
      email: "",
      setupAndWithdrawal: false,
      withdrawalOnly: false,
    },
  });
  const onSubmit = (e) => {};

//   const [text, setText] = useState("");
//   const [isFocused, setIsFocused] = useState(false);

//   const handleFocus = () => setIsFocused(true);
//   const handleBlur = () => setIsFocused(false);
//   const handleChange = (e) => setText(e.target.value);
  return (
    <div className="w-full">
      <div className="text flex items-center gap-5 mb-5">
        <button
          onClick={() => navigate(-1)}
          className="text bg-sky-500 w-5 h-5 flex text-white items-center justify-center rounded-full"
        >
          <PiArrowLeftFill />
        </button>
        <h1 className="text-sm font-bold text-[#090F47] ">
          Create Consultation
        </h1>
      </div>
      <div className="text bg-white w-full min-h-[70vh] px-5 py-8">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <FormField
              control={form.control}
              name="managerName"
              render={({ field }) => (
                <FormItem>
                  <div className="text border-b">
                    <Label className="text font-normal text-sm">
                      Doctor's Name
                    </Label>
                    <FormControl>
                      <Input
                        className="border-0 px-3"
                        placeholder=""
                        {...field}
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="managerName"
              render={({ field }) => (
                <FormItem>
                  <div className="text border-b">
                    <Label className="text font-normal text-sm">
                      Definitive/provisional
                    </Label>
                    <FormControl>
                      <Input
                        className="border-0 px-3"
                        placeholder=""
                        {...field}
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="text mt-6">
              <h1 className="text-sm">Doctor’s notes</h1>
              <div className="text-sm bg-[#F7F7F7] h-60 py-3 px-2 rounded-2xl">
                {/* <textarea
                  id="professional-textarea"
                  value={text}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  className={`${
                    isFocused ? "border-blue-500" : "border-gray-300"
                  } focus:ring-2 focus:ring-blue-500 focus:outline-none resize-none w-full h-40 p-3 rounded-md border bg-white text-sm text-gray-900 placeholder-gray-400`}
                  placeholder="Type your message here..."
                />
                <p className="text-gray-500 text-xs">Max 500 characters</p> */}
              </div>
            </div>
            <div className="text mt-6">
              <h1 className="text-sm">Consultation Summary</h1>
              <div className="text-sm bg-[#F7F7F7] h-60 py-3 px-2 rounded-2xl"></div>
            </div>

            <div className="text w-full text-end">
              <PostABillModal add={true} />
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default CreateConsultation;
