import React, { useCallback, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/shadcn/ui/dialog";
import { useNavigate } from "react-router-dom";
const ConsultationTypeModal = () => {
    const navigate = useNavigate();
  return (
    <div>
         <Dialog>
        <DialogTrigger asChild>
          <button className="bg-[#2CBCEF] flex justify-center items-center gap-2 w-full py-4 rounded-lg text-white ">
            Post a Bill
          </button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px] bg-white">
          <DialogHeader>
            <DialogTitle className="my-3 text-[#343673] font-bold text-center">
            What Type of Consultation ?
            </DialogTitle>
            <DialogDescription>
              <div className="text">
               <div className="text-center w-full px-7">
                <button onClick={() => navigate('/hospital/consultation-history', {state: "online"})} className="text border w-full py-3 my-2 rounded-lg bg-[#2CBCEF] text-white">Online Consultation</button>
               </div>
               <div className="text-center w-full px-7">
                <button  onClick={() => navigate('/hospital/consultation-history', {state: "offline"})} className="text border w-full py-3 my-2 rounded-lg border-[#2CBCEF] text-[#2CBCEF]">Offline Consultation</button>
               </div>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ConsultationTypeModal